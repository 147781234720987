.expertise-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-family: 'sans-serif';
  }
  .Expertise{
    /* margin: 7vh 0vw; */
    padding: 7vh 7vw;
  }
h1{
    text-align: center;
    font-family: 'sans-serif';
} 
  .expertise-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .expertise-item {
    text-align: left;
    padding: 25px;
    width: 25vw;
    height: 20vh;
    border: 2px solid #f6cc7f;
    background-color: #fff;
    border-radius: 5px;
    transition: transform 0.3s;
  }
  
  .expertise-item:hover {
    transform: scale(1.05);
  }

  @media (max-width: 900px) {
    .expertise-container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto); /* Set 3 rows */
    }
    .expertise-item {
      padding: 10px;
      width: 35vw;
      height: 25vh;
    }
  }

  @media (max-width: 600px) {
    .expertise-container {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, auto); /* Set 3 rows */
    }
    .expertise-item {
      padding: 15px;
      width: 60vw;
      height: fit-content;
    }
  }
  