.Expertise{
    position: relative;
    overflow: hidden;
}
.hero-container {
    position: relative;
    overflow: hidden;
  }
.dot-container {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .dot {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    animation: move 60s linear infinite;
  }
  
  @keyframes move {
    0% {
      transform: translateY(-50px) translateX(-50px);
    }
    25% {
      transform: translateY(25px) translateX(25px); /* Adjust movement distance */
    }
    50% {
      transform: translateY(100px) translateX(100px);
    }
    75% {
      transform: translateY(50px) translateX(50px);
    }
    100% {
      transform: translateY(0px) translateX(0px);
    }
  }
  