/* .Footer{
    width: 100%;
    height: 50vh;
    z-index: 0;
} */
/* background-color: #d7fcfe; */
.Footer{
    display: flex;
}
.Footer .Footer_left{
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 5vw;
    background-color: #f9dc4a;
}
.Footer_right{
    display: flex;
    padding: 10vh 5vw;
    width: 100%;
    background-size: cover;
    background-color: #d7fcfe;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Vertical_text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0vh 0vw;
    padding-left: 2.5vw;
    writing-mode: vertical-rl; /* Set vertical writing mode */
    text-orientation: mixed;
    transform: rotate(180deg); /* Ensure text is upright */
  }
  .Vertical_text a{
    padding: 1vh 0vw;
    color: #000;
    font-size: 1rem;
    text-decoration: none;
  }
  .Horizontal_text p{
    font-size: .9rem;
  }
  .Horizontal_text h1{
    font-size: 4rem;
  }
  .Horizontal_text p{
    text-align: left;
  }
  .Horizontal_text .H_p{
    font-size: 1.1rem;
  }
  .Footer_icon{
    font-size: 30px;
    color: black;
    padding: 5px;
  }
    hr{
        height: 1.5vh;
        background-color: #000;
        width: 15vw;
        border: none;
  }
  @media (max-width: 1180px) {
    .Horizontal_text h1{
        font-size: 2rem;
      }
  }
  @media (max-width: 840px){
    .Footer{
        display: grid;
        grid-template-rows: 1fr 1fr;
    }
    .Footer .Footer_left{
        width: auto;
    }
    .Footer_right{
        width: auto;
    }
  }