.Download_CV{
    margin-top: 2vh;
}
.Download_CV button{
    text-align: center;
    width: fit-content;
    height: 5vh;
    font-size: 1.5rem;
    color: #000;
    background-color: #ffc107;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 1s ease;
    transition: 1s;

}
button:hover{
    background-color: transparent;
    border: solid 1px #000;
    border-radius: 10px;
    color: #000;
}