.navbar {
    background-color: #fff;
    /* #171821 */
    padding: 0px 10px;
    font-family: 'sans-serif';
    z-index: 1;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0px auto;
  }
  
  .navbar-logo h1 {
    color: #000;
    font-size: 2.5rem;
  }
  .navbar-logo a{
    text-decoration: none;
  }
  
  .navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-links a {
    color: #000;
    font-size: 1.25rem;
    text-decoration: none;
    margin: 0 15px;
  }
  
  .navbar-toggle {
    display: none;
    z-index: 2;
    flex-direction: column;
    cursor: pointer;
  }
  .Nav_btn{
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
  }
  @media screen and (max-width: 840px) {
    .navbar-links {
      position: absolute;
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #000;
      text-align: center;
    }
  
    .navbar-links.active {
      position: relative;
      width: fit-content;
      display: flex;
      background-color: #fff;
      color: #000;
      justify-content: center;
      align-items: flex-end;
      text-align: right;
      z-index: 1;
      float: right;
    }
    .navbar-links a{
      color: #000;
      padding: 1vh 0vw;
    }
  
    .navbar-toggle {
      display: flex;
    }
    .barr {
      width: 50px;
      height: 5px;
      background-color: #000;
      margin: 3px 0;
    }
  }
  @media screen and (max-width: 480px){
    .navbar-logo h1 {
      color: #000;
      font-size: 1.5rem;
    }
  }
  