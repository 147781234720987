.contact-me-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  
  .contact-me-popup {
    background-color: #ccc;
    padding: 20px;
    border-radius: 10px;
    max-width: 50vw;
    width: 100%;
    color: #000;
    text-align: left;
    /* 
    background: linear-gradient(to right, #FFD3A5, #FD6585); */
    background-color: #fff;
  }
  
  .close-button {
    position: relative;
    top: -280px;
    font-size: 2.5rem;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
  }
  
  .form-group {
    margin-bottom: 20px;
    width: 95%;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group textarea {
    height: 100px;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  .Contact_h1{
    font-size: 2rem;
  }
  