  .progress-bars-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 2vw;
    width: min-content;
    margin-top: 5vh;
  }
  
  .progress-bar {
    width: calc(50% - 10px);
    padding: 0vh 10vw;
  }
  
  .bar-label {
    display: block;
    margin-bottom: 5px;
    font-family: 'sans-serif';
    font-size: larger;
  }
  
  .bar {
    background-color: #f2f2f2;
    width: 25vw;
    height: 20px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: #f6cc7f;
    transition: width 0.3s ease-in-out;
  }
  
  @media (max-width: 768px) {
    .progress-bars-container{
        width: min-content;
    }
    .progress-bar {
        padding: 1vh 8vw;
      width: calc(100% - 20px); /* Full width on smaller screens */
    }
  }
  
  