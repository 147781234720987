  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .projects-container-h1{
    font-size: 5rem;
    text-align: center;
  }
  
  .tabs button {
    width: fit-content;
    height: 4vh;
    background-color: #ffc107;
    border: solid 1px #000;
    margin: 3px;
    border-bottom: none;
    margin-bottom: 0px;
    font-size: large;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .projects-section {
    padding: 10vh;
    justify-content: center;
  }
  hr{
    width: 100%;
    height: 1px;
    padding: 0px;
    margin: 0px;
  }
  @media (max-width:840px){
    .tabs button {
      height: 7vh;
      margin: 1px;
    }
  }
