   .illustrations-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .Load_btn{
    width: 100%;
    padding: 1vh;
    display: flex;
    justify-content: center;
  }
  
  .load-more-btn {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: large;
    background-color: #ffc107;
    color: white; 
    border: none;
    width: max-content;
    height: auto;
    border-radius: 5px;
    font-size: xx-large;
    cursor: pointer;
    transition: .5s;
  }
  .load-more-btn:hover{
    border: solid 1px #000;
    background-color: transparent;
    color: #000;
  }
  @media (max-width: 839px) {
    .illustrations-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .card {
    width: 100%;
    max-width: max-content; /* Adjust maximum width as needed */
    border: 1px solid #ddd; /* Border for illustration purposes */
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto; /* Center the card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center; /* Add a subtle shadow */
  }
  
  .card-image {
    display: block;
    width: 100%;
    transition: 1s; /* Ensure the image covers the entire space */
  }
  .card-image:hover{
    transform: scale(1.1);
  }
  
  .card-content {
    padding: 20px;
    text-align: center;
  }
  
  .card-heading {
    margin-top: auto;
    margin-bottom: 0;
    bottom: 0px;
    font-size: 18px;
    font-weight: bold;
  }
  