.hire-button {
    padding: 10px 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    background-color: #ffc107;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 1s ease;
    transition: 1s;
  }
  
  .hire-button:hover {
    background-color: transparent;
    border: solid 1px #000;
    border-radius: 10px;
    color: #000;
  }
  