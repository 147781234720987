.hero-container {
    background-color: #fff;
    /* #171821 */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5vh 0;
    color: #000;
    height: max-content;
    font-family: 'sans-serif';
  }
  .line{
    height: 100%;
    color: black;
    width: 1px;
  }
  
  .hero-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .hero-details {
    position: relative;
    z-index: 1;
    text-align: center;
  }

  .hero-details h1 {
    font-size: 4.5rem;
    margin-bottom: 5px;
  }
  .hero-details p{
    font-size: 2rem;
    margin-bottom: 7.5px;
  }
  #P-small{
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .hero-details .typed-text {
    font-size: 2.2rem;
    color: #ffc107;
  }

  @media screen and (min-width: 480px) and (max-width: 840px){
    .hero-container {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: max-content;
        padding: 1vh 0vw;
      }
    
      .hero-details {
        text-align: left;
      }
    
      .hero-image-container {
        margin-top: 0;
        margin-left: 20px;
      }
      .hero-details h1 {
        font-size: 2rem;
        margin-bottom: 10px;
      }
      .hero-details p{
        font-size: 1rem;
      }
      .hero-details .typed-text {
        font-size: 1.5rem;
        color: #f6cc7f;
      }
      .hero-image{
        display: none;
      }
  }
  @media screen and (max-width: 480px){
    .hero-details h1 {
      font-size: 2rem;
    }
    .hero-details p{
      font-size: 1.5rem;
    }
    .hero-details .typed-text {
      font-size: 1.7rem;
    }
  }
  
  