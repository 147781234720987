.education-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 2vh auto;
  }
  
  .education-heading {
    font-size: 4rem;
    text-align: center;
  }
  
  .education-content {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .education-card {
    flex: 1;
    background-color: #f9f9f9;
    background-color: #d7fcfe; /* Background color for card */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 1s ease; /* Add shadow for card */
  }
  .education-card img{
    width: 150px;
  }
  
  .college-name {
    margin-top: 0;
    font-size: 24px;
  }
  
  .degree-name,
  .duration {
    margin: 10px 0;
  }
  .duration{
    font-weight: bold;
  }
  .education-card:hover {
    transform: scale(1.05); /* Increase size of card on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }
  
  /* Media queries for responsiveness */
  
  @media (max-width: 840px) {
    .education-content {
      flex-direction: column;
      align-items: center;
    }
  
    .education-image,
    .education-card {
      width: fit-content;
      margin-right: 0;
      margin-bottom: 20px;
    }
    .education-card:hover {
      transform: scale(1); /* Increase size of card on hover */
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    }
  }
  