
.about-container {
  display: grid;
  grid-template-columns: 1fr; /* By default, display in a single column */
  gap: 20px;
  padding: 0vh 7.5vw;
  font-family: 'sans-serif';
  /* background-color: #ccc; */
}

.image-container {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.About_img{
    width: 480px;
    border-right: 2.5px solid #f6cc7f;
    border-bottom: 7.5px solid #f6cc7f;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.text-container h1 {
  font-size: 3rem;
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 2px solid aqua;
}

.text-container p {
  font-size: 1.5rem;
  margin-bottom: -5px;
}
@media (min-width: 840px) {
  .about-container {
    grid-template-columns: 1fr 2fr; /* Display in two columns on screens larger than 768px */
  }
  .image-container{
   justify-content: right;
  }
}

@media (max-width: 600px){
  .text-container p{
    font-size: large;
  }
  .About_img{
    width: 360px;
    border-right: 2.5px solid #f6cc7f;
    border-bottom: 7.5px solid #f6cc7f;
}
}
